import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

export function DiscardChangesDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <ConfirmDialog
      open={open}
      title={t(langKeys.discard_changes_dialog_title)}
      description={t(langKeys.discard_changes_dialog_description)}
      cancelLabel={t(langKeys.discard_changes_dialog_cancel)}
      confirmLabel={t(langKeys.discard_changes_dialog_confirm)}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}
